



























































import { Component, Vue } from "vue-property-decorator";
import { AuthLoginBody } from "operatn-api-client";
import { ActionTypes } from "@/store";

@Component
export default class Login extends Vue {
  /* DATA */

  private username: string | null = null;
  private password: string | null = null;
  private showCard = false;
  private showPassword = false;

  private loginFormValid = false;
  private loading = false;

  /* GETTERS AND SETTERS */

  get loginBody() {
    return {
      username: this.username,
      password: this.password,
    };
  }

  get passwordType(): "text" | "password" {
    return this.showPassword ? "text" : "password";
  }
  get passwordIcon(): "mdi-eye" | "mdi-eye-off" {
    return this.showPassword ? "mdi-eye-off" : "mdi-eye";
  }

  get requestedRoute(): string {
    return (this.$route.query.requestedRoute as string) ?? "/";
  }

  /* METHODS */

  reset(): void {
    this.username = "";
    this.password = "";
  }

  async login(): Promise<void> {
    if (this.loginFormValid && !this.loading) {
      try {
        this.loading = true;
        await this.$store.dispatch(ActionTypes.LOGIN, this.loginBody as AuthLoginBody);
        this.$router.replace(this.requestedRoute);
      } catch (error) {
        if (error) {
          this.$store.dispatch(ActionTypes.SHOW_ERROR_DIALOG, "Credenziali errate");
        }
      } finally {
        this.loading = false;
      }
    }
  }

  /* LIFE CYCLE */

  mounted() {
    this.showCard = true;
  }
}
