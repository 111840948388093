import { render, staticRenderFns } from "./ContrattiEmailFirma.vue?vue&type=template&id=5c084372&scoped=true&"
import script from "./ContrattiEmailFirma.vue?vue&type=script&lang=ts&"
export * from "./ContrattiEmailFirma.vue?vue&type=script&lang=ts&"
import style0 from "./ContrattiEmailFirma.vue?vue&type=style&index=0&id=5c084372&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c084372",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VScaleTransition } from 'vuetify/lib/components/transitions';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAlert,VBtn,VCard,VCardActions,VCardText,VCol,VContainer,VFileInput,VForm,VMain,VRow,VScaleTransition,VSkeletonLoader,VToolbar,VToolbarTitle})
