



















import { Component, Vue } from "vue-property-decorator";

import OperatnNotFound from '@/components/gears/misc/OperatnNotFound.vue';

@Component({
  components: {
    OperatnNotFound
  }
})
export default class NoFound extends Vue {
  /* DATA */

  private showCard = false;

  /* LIFE CYCLE */

  mounted() {
    this.showCard = true;
  }
}
