







































import { Component, Prop, Mixins } from "vue-property-decorator";
import { ActionTypes } from "@/store";
import { ContrattiReturned, ContrattiPDFTypes } from "operatn-api-client";

import ContrattoHandlerMixin from '@/mixins/handlers/ContrattoHandlerMixin';

@Component
export default class ContrattiEmailFirma extends Mixins(ContrattoHandlerMixin) {
  /* PROPS */
  @Prop({ type: String, required: true })
  token!: string;

  /* DATA */

  private fetching = false;
  private contratto: ContrattiReturned | null = null;

  private file: File | null = null;
  private showCard = false;

  private formValid = false;
  private loading = false;

  /* METHODS */

  reset(): void {
    this.file = null;
  }

  async uploadFile(): Promise<void> {
    if (this.formValid && !this.loading) {
      try {
        this.loading = true;
        const formData = new FormData();
        formData.append('contratto', this.file as File);
        const type = this.contratto?.tokenEmailChiusuraAnticipata === this.token ? ContrattiPDFTypes.CHIUSURA_ANTICIPATA : ContrattiPDFTypes.CONTRATTO;
        await this.uploadContrattoFirmaFromEmail(this.token, formData, type);
        this.reset();
        this.$store.dispatch(ActionTypes.SHOW_SUCCESS_DIALOG, "Contratto inviato correttamente, il documento sarà visionato e confermato dai nostri operatori");
      } catch (error) {
        if (error) {
          this.$store.dispatch(ActionTypes.SHOW_ERROR_DIALOG, "Errore nell'inviare contratto");
        }
      } finally {
        this.loading = false;
      }
    }
  }

  /* LIFE CYCLE */

  async mounted() {
    this.showCard = true;
    try {
      this.fetching = true;
      this.contratto = await this.getContrattoByToken(this.token);
    } catch (error) {
    } finally {
      this.fetching = false;
    }
  }
}
