

















































import { Component, Vue } from "vue-property-decorator";
import { ActionTypes } from "@/store";
import { NotFoundError } from "operatn-api-client";

@Component
export default class AskPasswordRecovery extends Vue {
  /* DATA */

  private loading = false;
  private username: string | null = null;
  private showCard = false;
  private formValid = false;

  /* METHODS */

  reset(): void {
    this.username = "";
  }

  async askPasswordRecovery(): Promise<void> {
    if (this.formValid && !this.loading) {
      try {
        this.loading = true;
        await this.$api.utenti.askPasswordRecovery(this.username as string);
        this.reset();
        this.$store.dispatch(ActionTypes.SHOW_SUCCESS_DIALOG, "Ti è stata inviata una email per recuperare la password dell'utente");
      } catch (error) {
        if (error) {
          if (error instanceof NotFoundError) {
            this.$store.dispatch(ActionTypes.SHOW_ERROR_DIALOG, "Utente non trovato");
          } else {
            this.$store.dispatch(ActionTypes.SHOW_ERROR_DIALOG, "Errore nel server");
          }
        }
      }
      finally {
        this.loading = false;
      }
    }
  }

  /* LIFE CYCLE */

  mounted() {
    this.showCard = true;
  }
}
