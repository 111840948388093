


































































import { Component, Prop, Vue } from "vue-property-decorator";
import { ActionTypes } from "@/store";
import { UtentiReturned } from "operatn-api-client";

@Component
export default class Login extends Vue {
  /* PROPS */
  @Prop({ type: String, required: true })
  token!: string;

  /* DATA */

  private fetching = false;
  private utente: UtentiReturned | null = null;

  private password: string | null = null;
  private passwordConfirmation: string | null = null;
  private showCard = false;
  private showPassword = false;

  private formValid = false;
  private loading = false;

  /* GETTERS AND SETTERS */

  get passwordType(): "text" | "password" {
    return this.showPassword ? "text" : "password";
  }
  get passwordIcon(): "mdi-eye" | "mdi-eye-off" {
    return this.showPassword ? "mdi-eye-off" : "mdi-eye";
  }

  /* METHODS */

  reset(): void {
    this.password = "";
    this.passwordConfirmation = "";
  }

  async recoverPassword(): Promise<void> {
    if (this.formValid && !this.loading) {
      try {
        this.loading = true;
        await this.$api.utenti.recoverPassword(this.token, { password: this.password as string });
        this.reset();
        this.$store.dispatch(ActionTypes.SHOW_SUCCESS_DIALOG, "Password cambiata correttamente. Premi sull'icona in basso a destra per andare al login");
      } catch (error) {
        if (error) {
          this.$store.dispatch(ActionTypes.SHOW_ERROR_DIALOG, "Errore nel cambiare la password");
        }
      } finally {
        this.loading = false;
      }
    }
  }

  /* LIFE CYCLE */

  async mounted() {
    this.showCard = true;
    try {
      this.fetching = true;
     this.utente = await this.$api.utenti.getUserByRecoveryToken(this.token);
    } catch (error) {
    } finally {
      this.fetching = false;
    }
  }
}
